<template>
    <b-card-body>
        <div class="document-types">
            <a
                v-for="option in documentTypes"
                :key="'wstm-' + option.key"
                class="worksheet-button d-flex flex-column align-items-center"
                :href="`//${subdomains[option.key]}/${option.key}-maker`"
                target="_blank"
                @click="onNewDocument(option.name)"
            >
                <span class="img-container">
                    <img :src="getImage(option.key)" />
                </span>
                <span v-if="option.key === 'worksheet'" class="worksheet-type-button-text w-100 text-center">
                    {{ option.name }}
                </span>
                <span v-else class="worksheet-type-button-text w-100 text-center">
                    {{ option.name }}

                    <PremiumMarker v-if="!hasFeature('full-access', null, option.key)" :page="option.key">
                        <div class="mb-2 f-13">
                            Premium activities are available for paid members.
                            <span v-if="neverHadTrial">
                                You can demo this activity without a membership, and a 30-day free trial is available to make
                                sure it meets your needs.
                            </span>
                        </div>
                    </PremiumMarker>
                </span>
            </a>

            <a
                class="worksheet-button d-flex flex-column align-items-center position-relative"
                :href="crossword_url"
                target="_blank"
                @click="onNewDocument('Crossword')"
            >
                <span class="img-container">
                    <img :src="crosswordPng" />
                </span>
                <span class="worksheet-type-button-text w-100 text-center">
                    Crossword
                    <PremiumMarker v-if="!hasFeature('full-access', null, 'crossword')">
                        <div class="mb-2 f-13">
                            Premium activities are available for paid members.
                            <span v-if="neverHadTrial">
                                You can demo this activity without a membership, and a 30-day free trial is available to make
                                sure it meets your needs.
                            </span>
                        </div>
                    </PremiumMarker>
                </span>
            </a>

            <a
                class="worksheet-button d-flex flex-column align-items-center position-relative"
                :href="wordsearch_url"
                target="_blank"
                @click="onNewDocument('Word Search')"
            >
                <span class="img-container">
                    <img :src="wordsearchPng" />
                </span>
                <span class="worksheet-type-button-text w-100 text-center">
                    Word Search
                    <PremiumMarker v-if="!hasFeature('full-access', null, 'wordsearch')">
                        <div class="mb-2 f-13">
                            Premium activities are available for paid members.
                            <span v-if="neverHadTrial">
                                You can demo this activity without a membership, and a 30-day free trial is available to make
                                sure it meets your needs.
                            </span>
                        </div>
                    </PremiumMarker>
                </span>
            </a>
        </div>
    </b-card-body>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import PremiumMarker from '../widgets/premium-marker.vue'
import StatsigEvent from '../common/StatsigEvent'
import StatsigHelper from '../mixins/StatsigHelper'
import EditPayWall from '../mixins/EditPayWall'
import crossword_image from '../../img/worksheets/crossword.png'
import wordsearch_image from '../../img/worksheets/wordsearch.png'
import bingo_image from '../../img/worksheets/bingo.jpg'
import flashcard_image from '../../img/worksheets/flashcard.jpg'
import worksheet_image from '../../img/worksheets/worksheet.jpg'
import Common from '../mixins/Common'

export default defineComponent({
    name: 'ActivityChooser',
    components: {
        PremiumMarker,
    },
    mixins: [EditPayWall, StatsigHelper, Common],
    data() {
        return {
            crossword_url: null,
            wordsearch_url: null,
            crosswordPng: crossword_image,
            wordsearchPng: wordsearch_image,
            bingoJpg: bingo_image,
            flashcardJpg: flashcard_image,
            worksheetJpg: worksheet_image,
            subdomains: window.subdomains,
        }
    },
    computed: {
        ...mapGetters({
            hasPlan: 'user/hasPlan',
            isLoggedIn: 'user/isLoggedIn',
            documentTypes: 'document/allDocumentTypes',
        }),
        ...mapState(['user']),
    },
    mounted() {
        this.crossword_url = window.crossword_url ?? ''
        this.wordsearch_url = window.wordsearch_url ?? ''
    },
    methods: {
        onNewDocument(type) {
            this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.NEW_DOCUMENT,
                value: type,
            })
            this.logNavigation(type.toLowerCase())
            this.$eventApi.create_event('document-type-selected', `Document Type: ${type}`)
        },
        getImage(brand) {
            let images = {
                worksheet: this.worksheetJpg,
                bingo: this.bingoJpg,
                flashcard: this.flashcardJpg,
            }
            return images[brand.toLowerCase()]
        },
    },
})
</script>
